<template>
  <div>
    <v-row v-if="!isAPPView" dense no-gutters justify="space-between">
      <v-col cols="12" sm="auto">
        <v-tabs
          v-model="tab"
          exact
          exact-active-class="primary--text font-weight-bold"
          background-color="transparent"
        >
          <v-tabs-slider
            :color="!onTab ? 'rgba(0, 0, 0, 0)' : 'primary'"
          ></v-tabs-slider>
          <v-tab
            v-for="item in filteredItems"
            :disabled="item.disabled"
            :to="`${item.to}`"
            :key="item.to"
          >
            <span> {{ item.label }} </span>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="auto"
        class="d-flex justify-end align-center"
      >
        <Clock class="mx-8" />
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row
        dense
        no-gutters
        class="title px-4 pt-4 pb-1 d-flex"
        v-if="pageData"
      >
        <v-col cols="auto">
          <h3 class="accent--text">
            {{ pageData.displayName }} {{ subLinkName }}
          </h3>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" v-if="searchable">
          <v-text-field
            v-model="search"
            label="搜尋"
            outlined
            rounded
            dense
            hide-details
            clearable
            clear-icon="mdi-close"
          ></v-text-field>
        </v-col>
      </v-row>
      <div>
        <router-view></router-view>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Clock from '@/components/Clock'
import { countdownReload } from '@/shared/utils'
import settings from '@/settings'

export default {
  name: 'SchoolOptionContainer',
  components: {
    Clock
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      search: '',
      tab: '',
      settingItem: null,
      city: settings.edu.city,
      onTab: true
    }
  },
  computed: {
    ...mapGetters({
      userRole: 'user/userRole',
      navs: 'page/navs',
      pageData: 'page/pageData',
      isAPPView: 'page/isAPPView'
    }),
    queryId() {
      return this.tagId
    },
    routerPath() {
      return this.$route.path
    },
    subLinkName() {
      return this.$route.meta.name ? `/ ${this.$route.meta.name}` : ''
    },
    searchable() {
      return this.$route.meta.search
    },
    items() {
      return [
        {
          label: '資訊總覽',
          to: `/school/${this.tagId}/overview`,
          disabled: false
        },
        {
          label: '資料查詢',
          to: `/school/${this.tagId}/analysis`,
          disabled: false
        },
        {
          label: '節電比較',
          to: `/school/${this.tagId}/comparison`,
          disabled: false
        },
        {
          label: '基本資料',
          to: `/school/${this.tagId}/info`,
          disabled: false
        },
        {
          label: '儲值結帳紀錄',
          to: `/school/${this.tagId}/deposit`,
          disabled: false
        },
        {
          label: '統計報表',
          to: `/school/${this.tagId}/report`,
          disabled: false
        },
        {
          label: '所有通知',
          to: `/school/${this.tagId}/warning`,
          disabled: false
        }
        // {
        //   label: '教室空氣品質',
        //   to: `/school/${this.tagId}/air`,
        //   disabled: false
        // }
      ]
    },
    filteredItems() {
      const items = [...this.items]
      if (['keelung', 'changhua'].includes(this.city)) {
        const depositIndex = this.items.findIndex((i) =>
          i.to.endsWith('deposit')
        )
        if (depositIndex !== -1) {
          items.splice(depositIndex, 1)
        }
      }
      if (
        this.city === 'tainan' &&
        this.userRole.role === 'SCH_ADMIN' &&
        !items.filter((v) => v.label === '設備異常管理').length
      )
        items.push({
          label: '設備異常管理',
          to: `/school/${this.tagId}/device`,
          disabled: false
        })
      return items
    }
  },
  watch: {
    navs() {
      this.getPageInfo()
    },
    queryId() {
      this.getPageInfo()
    },
    search() {
      if (this.$route.query.search !== this.search) {
        this.$router.replace({
          query: {
            search: this.search
          }
        })
      }
    },
    async routerPath() {
      this.onTab = true
      await this.$nextTick()
      if (this.routerPath.indexOf(this.tab) < 0) {
        this.onTab = false
      }
    }
  },
  methods: {
    ...mapActions({
      setPage: 'page/setPage',
      getPage: 'page/getPage',
      getIPCStatus: 'page/getIPCStatus'
    }),
    getPageInfo() {
      if (!this.pageData || this.pageData.id !== this.queryId) {
        this.getPage(this.queryId)
        // this.startCheckIPC()
      }
    },
    startCheckIPC() {
      console.log('start check IPC school')
      this.getIPCStatus()
      this.timerCheckIPC = countdownReload(this.getIPCStatus, 300)
    }
  },
  mounted() {
    // fetchData
    this.setPage(null)
    this.getPageInfo()
    if (this.$route.query && this.$route.query.search) {
      const search = this.$route.query.search
      this.search = search
    }
  },
  beforeDestroy() {
    clearInterval(this.timerCheckIPC)
  }
}
</script>
<style scoped></style>
